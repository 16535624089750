








































































































































































































































































































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import DataDisplayBox from "@/components/DataDisplayBox.vue";
import SearchableItemListing from "@/components/SearchableItemListing.vue";
import PrintableSearchResults from "@/components/PrintableSearchResults.vue";
import { Vehicle } from "@/assets/classes/Vehicle.ts";
import { Lienholder, Owner, Address, Stop } from "@/types/index.ts";
import RequestHandler from "@/assets/ts/requestHandler.ts";

const RH = new RequestHandler();

@Component({
  components: {
    DataDisplayBox,
    SearchableItemListing,
    PrintableSearchResults
  }
})
export default class SearchResults extends Vue {
  private vehicles: { [key: string]: any }[] = [];
  private resultVehicle = {} as Vehicle;
  private currentLienholder = {} as Lienholder;
  private currentOwner = {} as Owner;
  private currentLessor = {} as Owner;
  private currentLessee = {} as Owner;
  private currentStop = {} as Stop;
  private printDialog = false;
  private lienDialog = false;
  private searchedVehicle = "";
  private searching = true;
  private searchingText = "Searching...";
  private searchingInterval = 0;
  private lienTab = "1";
  private ownerTab = "1";
  private lessorTab = "1";
  private lesseeTab = "1";
  private stopTab = "1";
  private letterBin64 = "";
  private waitingOnLetter = true;
  private searchTime = new Date();

  get vehicleSummaryItems(): { [key: string]: any }[] {
    return [
      { label: "VIN", data: this.resultVehicle.vin },
      { label: "Make", data: this.resultVehicle.make },
      { label: "Model", data: this.resultVehicle.model },
      { label: "Year", data: this.resultVehicle.year },
      { label: "Odometer", data: this.resultVehicle.odometer }
    ];
  }

  get titleRegistrationItems(): { [key: string]: any }[] {
    return [
      { label: "Plate", data: this.resultVehicle.plate },
      { label: "Class", data: this.resultVehicle.plateClass },
      {
        label: "Issue Year",
        data: this.resultVehicle.plateIssueYear
      },
      {
        label: "Expiration Date",
        data: this.resultVehicle.expirationDate
      },
      { label: "Title Date", data: this.resultVehicle.titleDate },
      { label: "Title Number", data: this.resultVehicle.titleNumber },
      {
        label: "Purchase Date",
        data: this.resultVehicle.purchaseDate
      },
      {
        label: "Previous Title State",
        data: this.resultVehicle.previousTitleState
      }
    ];
  }

  get ownerInformationItems(): { [key: string]: any }[] {
    return [
      {
        label: "Owner " + (this.currentOwner.id + 1) + " Name",
        data: this.currentOwner.name
      },
      { label: "Conjuction Code", data: this.currentOwner.conjCode },
      { label: "Address", data: this.currentOwner.physicalAddress.address },
      { label: "City", data: this.currentOwner.physicalAddress.city },
      { label: "State", data: this.currentOwner.physicalAddress.state },
      { label: "Zip Code", data: this.currentOwner.physicalAddress.zip }
    ];
  }

  get mailingAddressItems(): { [key: string]: any }[] {
    return [
      {
        label: "Address",
        data: this.resultVehicle.owners[0].mailingAddress.address
      },
      { label: "City", data: this.resultVehicle.owners[0].mailingAddress.city },
      {
        label: "State",
        data: this.resultVehicle.owners[0].mailingAddress.state
      },
      {
        label: "Zip Code",
        data: this.resultVehicle.owners[0].mailingAddress.zip
      }
    ];
  }

  get lessorInformationItems(): { [key: string]: any }[] {
    return [
      { label: "Lessor Name", data: this.resultVehicle.lessor.name },
      {
        label: "Address",
        data: this.resultVehicle.lessor.physicalAddress.address
      },
      { label: "City", data: this.resultVehicle.lessor.physicalAddress.city },
      { label: "State", data: this.resultVehicle.lessor.physicalAddress.state },
      { label: "Zip Code", data: this.resultVehicle.lessor.physicalAddress.zip }
    ];
  }

  get lesseeInformationItems(): { [key: string]: any }[] {
    return [
      {
        label: "Lessee " + (this.currentLessee.id + 1) + " Name",
        data: this.currentLessee.name
      },
      { label: "Conjuction Code", data: this.currentLessee.conjCode },
      { label: "Address", data: this.currentLessee.physicalAddress.address },
      { label: "City", data: this.currentLessee.physicalAddress.city },
      { label: "State", data: this.currentLessee.physicalAddress.state },
      { label: "Zip Code", data: this.currentLessee.physicalAddress.zip }
    ];
  }

  get lienInformationItems(): { [key: string]: any }[] {
    return [
      {
        label: "Lienholder " + (this.currentLienholder.count + 1) + " Name",
        data: this.currentLienholder.name
      },
      { label: "Start Date", data: this.currentLienholder.startDate },
      { label: "Address", data: this.currentLienholder.street },
      { label: "City", data: this.currentLienholder.city },
      { label: "State", data: this.currentLienholder.state },
      { label: "Zip Code", data: this.currentLienholder.zip }
    ];
  }

  get stopInformationItems(): { [key: string]: any }[] {
    return [
      { label: "Stop Code", data: this.currentStop.code },
      { label: "Name", data: this.currentStop.name },
      { label: "Add Date", data: this.currentStop.addDate }
    ];
  }

  get ownersSubset() {
    const ret = this.resultVehicle.owners.slice(0, 3);
    return ret;
  }

  get lesseesSubset() {
    const ret = this.resultVehicle.lessees.slice(0, 2);
    return ret;
  }

  public setResultVehicle(): void {
    try {
      const resultVehicle = localStorage.getItem("resultVehicle");
      if (resultVehicle) {
        localStorage.removeItem("resultVehicle");
        const tempVehicle: string = resultVehicle !== null ? resultVehicle : "";
        this.resultVehicle = new Vehicle(
          tempVehicle.includes("vehicleRecord") ? JSON.parse(tempVehicle) : null
        );
      } else {
        this.searching = false;
      }
    } catch (e) {
      this.searching = true;
      this.searchingText = "An error occurred while loading the search result";
    }
  }

  public printResults() {
    window.print();
  }

  public setLienholder(id = -1): void {
    if (id === -1) {
      try {
        this.currentLienholder = this.resultVehicle.lienholders[0];
      } catch (e) {
        return;
      }
    } else {
      const selectedLienholder = this.resultVehicle.lienholders.find(function(
        lienholder
      ) {
        return lienholder.id == id;
      });
      if (selectedLienholder) {
        this.currentLienholder = selectedLienholder;
      }
    }
  }

  public setOwner(id = -1): void {
    if (id === -1) {
      try {
        this.currentOwner = this.resultVehicle.owners[0];
      } catch (e) {
        return;
      }
    } else {
      const selectedOwner = this.resultVehicle.owners.find(function(owner) {
        return owner.id == id;
      });
      if (selectedOwner) {
        this.currentOwner = selectedOwner;
      }
    }
  }

  public setLessee(id = -1): void {
    if (id === -1) {
      try {
        this.currentLessee = this.resultVehicle.lessees[0];
      } catch (e) {
        return;
      }
    } else {
      const selectedLessee = this.resultVehicle.lessees.find(function(lessee) {
        return lessee.id == id;
      });
      if (selectedLessee) {
        this.currentLessee = selectedLessee;
      }
    }
  }

  public setStop(id = -1): void {
    if (id === -1) {
      try {
        this.currentStop = this.resultVehicle.stops[0];
      } catch (e) {
        return;
      }
    } else {
      const selectedStop = this.resultVehicle.stops.find(function(stop) {
        return stop.id == id;
      });
      if (selectedStop) {
        this.currentStop = selectedStop;
      }
    }
  }

  created() {
    this.$root.$emit("stop-loading-overlay");
    this.setResultVehicle();
    this.setOwner();
    this.setLessee();
    this.setLienholder();
    this.setStop();
  }
}
