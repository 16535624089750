import { Lienholder, Owner, Address, Stop } from "@/types/index.ts";

export class Vehicle {
  constructor(vehicleData: { [key: string]: any } = {}) {
    this.vehicle = vehicleData.searchResult.vehicleRecord.vehicle.ID;
    this.vin = vehicleData.searchResult.vehicleRecord.vehicle.vin;
    this.make = vehicleData.searchResult.vehicleRecord.vehicle.make;
    this.model = vehicleData.searchResult.vehicleRecord.vehicle.model;
    this.year = vehicleData.searchResult.vehicleRecord.vehicle.year;
    this.odometer = vehicleData.searchResult.vehicleRecord.vehicle.odometer;
    this.axles = vehicleData.searchResult.vehicleRecord.vehicle.axles;
    this.grossWeight =
      vehicleData.searchResult.vehicleRecord.vehicle.grossWeight;
    this.unladenWeight =
      vehicleData.searchResult.vehicleRecord.vehicle.unladenedWeight;
    this.purchaseDate = new Date(
      vehicleData.searchResult.vehicleRecord.vehicle.purchaseDate
    ).toLocaleDateString();
    this.searchDate = new Date(vehicleData.searchDate).toLocaleDateString();
    this.searchTime = new Date(vehicleData.searchDate).toLocaleTimeString();

    this.salesTax = "";

    this.owners = [];
    this.lessees = [];
    if (
      Object.prototype.hasOwnProperty.call(
        vehicleData.searchResult.vehicleRecord,
        "owners"
      )
    ) {
      const tempOwners = vehicleData.searchResult.vehicleRecord.owners.filter(
        (owner: any) => owner.ownershipRightDesc === "Owner"
      );
      const tempLessees = vehicleData.searchResult.vehicleRecord.owners.filter(
        (lessee: any) => lessee.ownershipRightDesc === "Lessee"
      );

      let count = 0;
      tempOwners.forEach((owner: { [key: string]: any }) => {
        this.owners.push({
          id: count,
          name: owner.name,
          physicalAddress: {
            address:
              (owner.physicalAddress.streetNumber
                ? owner.physicalAddress.streetNumber + " "
                : "") + owner.physicalAddress.address1,
            city: owner.physicalAddress.city,
            state: owner.physicalAddress.state,
            zip: owner.physicalAddress.zip
          },
          mailingAddress: owner.mailingAddress
            ? {
                address:
                  (owner.mailingAddress.streetNumber
                    ? owner.mailingAddress.streetNumber + " "
                    : "") + owner.mailingAddress.address1,
                city: owner.mailingAddress.city,
                state: owner.mailingAddress.state,
                zip: owner.mailingAddress.zip
              }
            : ({} as Address),
          conjCode:
            tempOwners.length < 2
              ? "N/A"
              : owner.conjCode === "1"
              ? "AND"
              : "OR"
        });
        count++;
      });

      count = 0;
      tempLessees.forEach((owner: { [key: string]: any }) => {
        this.lessees.push({
          id: count,
          name: owner.name,
          physicalAddress: {
            address:
              (owner.physicalAddress.streetNumber
                ? owner.physicalAddress.streetNumber + " "
                : "") + owner.physicalAddress.address1,
            city: owner.physicalAddress.city,
            state: owner.physicalAddress.state,
            zip: owner.physicalAddress.zip
          },
          mailingAddress: {} as Address,
          conjCode:
            tempLessees.length < 2
              ? "N/A"
              : owner.conjCode === "1"
              ? "AND"
              : "OR"
        });
        count++;
      });
    }

    this.lessor = {} as Owner;
    if (
      Object.prototype.hasOwnProperty.call(
        vehicleData.searchResult.vehicleRecord,
        "lessors"
      )
    ) {
      const lessorData = vehicleData.searchResult.vehicleRecord.lessors[0];
      this.lessor.id = lessorData.id;
      this.lessor.name = lessorData.name;
      this.lessor.physicalAddress = {
        address:
          (lessorData.physicalAddress.streetNumber
            ? lessorData.physicalAddress.streetNumber + " "
            : "") + lessorData.physicalAddress.address1,
        city: lessorData.physicalAddress.city,
        state: lessorData.physicalAddress.state,
        zip: lessorData.physicalAddress.zip
      };
      this.lessor.mailingAddress = {} as Address;
    }

    if (
      Object.prototype.hasOwnProperty.call(
        vehicleData.searchResult.vehicleRecord,
        "plate"
      )
    ) {
      this.plate = vehicleData.searchResult.vehicleRecord.plate.plate;
      this.plateClass = vehicleData.searchResult.vehicleRecord.plate.plateClass;
      this.plateIssueYear =
        vehicleData.searchResult.vehicleRecord.plate.issueYear;
      this.expirationDate = new Date(
        vehicleData.searchResult.vehicleRecord.plate.expirationDate
      ).toLocaleDateString();
    } else {
      this.plate = "";
      this.plateClass = "";
      this.plateIssueYear = "";
      this.expirationDate = "";
    }

    if (
      Object.prototype.hasOwnProperty.call(
        vehicleData.searchResult.vehicleRecord,
        "title"
      )
    ) {
      this.titleDate = new Date(
        vehicleData.searchResult.vehicleRecord.title.titleIssueDate
      ).toLocaleDateString();
      this.titleNumber =
        vehicleData.searchResult.vehicleRecord.title.currentTitleNo;
      this.previousTitleState =
        vehicleData.searchResult.vehicleRecord.title.formerTitleState;
    } else {
      this.titleDate = "";
      this.titleNumber = "";
      this.previousTitleState = "";
    }

    this.lienholders = [];
    if (
      Object.prototype.hasOwnProperty.call(
        vehicleData.searchResult.vehicleRecord,
        "lienholders"
      )
    ) {
      let lienCount = 0;
      vehicleData.searchResult.vehicleRecord.lienholders.forEach(
        (lienholder: { [key: string]: any }) => {
          this.lienholders.push({
            id: lienholder.id,
            name: lienholder.name,
            startDate: new Date(lienholder.lienDate).toLocaleDateString(),
            street:
              (lienholder.physicalAddress.streetNumber
                ? lienholder.physicalAddress.streetNumber + " "
                : "") + lienholder.physicalAddress.address1,
            city: lienholder.physicalAddress.city,
            state: lienholder.physicalAddress.state,
            zip: lienholder.physicalAddress.zip,
            count: lienCount
          });
          lienCount++;
        }
      );
    }

    this.stops = [];
    if (
      Object.prototype.hasOwnProperty.call(
        vehicleData.searchResult.vehicleRecord,
        "stops"
      )
    ) {
      let stopCount = 0;
      vehicleData.searchResult.vehicleRecord.stops.forEach(
        (stop: { [key: string]: any }, key: number) => {
          this.stops.push({
            id: key,
            code: stop.stopCode,
            name: stop.stopCodeDesc,
            addDate: stop.recordDate.slice(0, 10),
            count: stopCount
          });
          stopCount++;
        }
      );
    }

    this.searchID = vehicleData.searchID;
    if (vehicleData.searchResult.ncicResponse == "HIT") {
      this.ncicHit = true;
    } else {
      this.ncicHit = false;
    }
  }

  vehicle: string;
  vin: string;
  plate: string;
  make: string;
  model: string;
  year: string;
  odometer: number;
  axles: string;
  grossWeight: string;
  unladenWeight: string;
  plateClass: string;
  plateIssueYear: string;
  expirationDate: string;
  titleDate: string;
  titleNumber: string;
  purchaseDate: string;
  searchDate: string;
  searchTime: string;
  salesTax: string;
  previousTitleState: string;
  owners: Owner[];
  lessees: Owner[];
  lessor: Owner;
  lienholders: Lienholder[];
  stops: Stop[];
  ncicHit: boolean;
  searchID: number;
}
