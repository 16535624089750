




























import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class DataDisplayBox extends Vue {
  @Prop({ required: true }) data!: { [key: string]: string }[];
  @Prop({ required: true }) header!: string;
  @Prop({ default: true }) showSearch!: boolean;

  private searchText = "";

  get filteredItems(): {}[] {
    return this.data.filter(item => {
      return item["name"].toLowerCase().includes(this.searchText);
    });
  }
}
