

















import { Component, Prop, Vue } from "vue-property-decorator";
@Component
export default class DataDisplayBox extends Vue {
  @Prop({ required: true }) label!: string;
  @Prop({ required: true }) readonly data!: string;
}
